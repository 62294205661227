import {Vector3, BufferGeometry} from "three";
import {DragControls} from 'three/addons/controls/DragControls.js';
import {POLYGONS, VARS, VERTICES} from '../Global/variables';
import * as Details from "./AreaPitchAzimu";
import {sceneManager} from "../SetUps/SceneManager";
import {updateLabelPositions} from "./Labels";
import {calculatePitch} from "./AreaPitchAzimu";

let vertexIndex;

export const setSegmentDragControls = (index,setPolygonDetails) => {
    removeSegmentDragControls();
    VARS.segDrag = new DragControls(VERTICES[index], sceneManager.camera, sceneManager.renderer.domElement);

    VARS.segDrag.addEventListener("hoveron", dragHoverOn);
    VARS.segDrag.addEventListener("hoveroff", dragHoverOff);
    VARS.segDrag.addEventListener("dragend", (event) => dragEnd(event));
    VARS.segDrag.addEventListener("dragstart", (event) => dragStart(event));
    VARS.segDrag.addEventListener("drag", (event) => onDrag(event,setPolygonDetails));

    return VARS.segDrag;
};

export const removeSegmentDragControls = () => {
    if (VARS.segDrag) {

        VARS.segDrag.dispose();
        VARS.segDrag.removeEventListener("hoveron", dragHoverOn);
        VARS.segDrag.removeEventListener("hoveroff", dragHoverOff);
        VARS.segDrag.removeEventListener("dragend", dragEnd);
        VARS.segDrag.removeEventListener("dragstart", dragStart);
        VARS.segDrag.removeEventListener("drag", onDrag);
        VARS.segDrag = null;
    }
};

const dragHoverOn = (event) => {
    event.object.material.opacity = 1;

};

const dragHoverOff = (event) => {
    event.object.material.opacity = 0.6;
};
const dragStart = (event) => {
    let vertexId = event.object.uuid;
    let segment = event.object.parent;
    for (let i in segment.children) {
        if (segment.children[i].uuid === vertexId) {
            vertexIndex = i;
        }
    }
    sceneManager.controls.enabled = false;
}

const dragEnd = (event) => {
    sceneManager.controls.enabled = true;
}

const onDrag = (event,setPolygonDetails) => {

    let geometry = event.object.geometry;
    geometry.computeBoundingBox();
    let center = new Vector3();
    geometry.boundingBox.getCenter(center);
    event.object.localToWorld(center);


    event.object.parent.geometry.attributes.position.setXYZ(
        vertexIndex,
        center.x,
        center.y,
        center.z
    );
    event.object.parent.geometry.attributes.position.needsUpdate = true;
    let coordinates = [];
    for (
        let i = 0;
        i < event.object.parent.geometry.attributes.position.count;
        i++
    ) {
        coordinates.push({
            x: event.object.parent.geometry.attributes.position.getX(i),
            y: event.object.parent.geometry.attributes.position.getY(i),
            z: event.object.parent.geometry.attributes.position.getZ(i),
        });
    }

    let edges = [];
    for (let i = 0; i < coordinates.length; i++) {
        edges.push(
            new Vector3(
                coordinates[i].x,
                coordinates[i].y,
                coordinates[i].z
            )
        );
        if (i + 1 < coordinates.length) {
            edges.push(
                new Vector3(
                    coordinates[i + 1].x,
                    coordinates[i + 1].y,
                    coordinates[i + 1].z
                )
            );
        }
    }
    edges.push(
        new Vector3(
            coordinates[0].x,
            coordinates[0].y,
            coordinates[0].z
        )
    );
    geometry = new BufferGeometry().setFromPoints(edges);

    event.object.parent.children.slice(-1)[0].geometry.attributes.position =
        geometry.attributes.position;
    event.object.parent.children.slice(
        -1
    )[0].geometry.attributes.position.needsUpdate = true;

    geometry.dispose();
    updateLabelPositions(event, center)
    updateDetails(event.object.parent.uuid,setPolygonDetails)

}

function updateDetails(parentId,setPolygonDetails) {
    let segments = POLYGONS
    for (let index = 0; index < segments.length; index++) {
        if (segments[index].shape.uuid === parentId) {
            let lines = segments[index].edges;

            let res = calculatePitch(lines)
            let pitch = res.pitch_angle;
            let area = Details.getPolyArea(lines).toFixed(2);
            let azimu = null;
            azimu = Details.getAzimuth(res.p2, res.p1).toFixed(2);
            let data={ pitch:pitch,area:area,azimu:azimu }
            setPolygonDetails(data);
            segments[index].details = data;
            break;
        }
    }
}