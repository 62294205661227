import {projectSegmentTo2D} from "../Calculations/Polygon2dConversions";
import {projectObstacleTo2D} from "../Calculations/Obstacle2dConversions";
import {DXF_VARS, POLYGONS} from "../../Global/variables";
import {projectDormerTo2D} from "../Calculations/Dormer2dConversion";
import {getVerticesData} from "../../DataUtils/SavaData";


export function get2dData(segment, obstruction,segmentIndex) {

    let segment2dPoints = projectSegmentTo2D(segment)
    let Obstacle2dPoints = [];
    let dormerData = []

    if (obstruction.length > 0) {
        obstruction.forEach((obs) => {
            Obstacle2dPoints.push({polygon:projectObstacleTo2D(obs)})
        })
    }

    if(DXF_VARS.dormerData.length > 0){
        DXF_VARS.dormerData[segmentIndex].forEach((childData)=> {
            let array = POLYGONS[childData].shape.geometry.attributes.position.array;
            let vertices = getVerticesData(array);
            dormerData.push(projectDormerTo2D(vertices));
        });
    }
    // console.log(`dormer data for segmnet ${segmentIndex} `)
    // console.log(dormerData)


    return {segment2dPoints, Obstacle2dPoints,dormerData}

}
