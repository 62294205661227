import { DXF_VARS, POLYGONS } from "../Global/variables";
import { get2dData } from "../Files/Pdf/Generate2dPoints";
import { isPolygonInsidePolygon } from "../Files/Calculations/DormerIdentification";

export const transformPolygonData = () => {
    let polygons = POLYGONS;
    let transformedData = [];
    DXF_VARS.dormerData.length = 0;

    for (let i = 0; i < polygons.length; i++) {
        let array = polygons[i].shape.geometry.attributes.position.array;
        let vertices = getVerticesData(array);
        findDormers(vertices, i);
    }
    traverseAndClean();


    for (let i = 0; i < polygons.length; i++) {
        let facet = polygons[i];
        let array = [];
        facet.edges.map(edge => {
            array.push(edge.point1.x);
            array.push(edge.point1.y);
            array.push(edge.point1.z);
        });
        let polygonShape = getVerticesData(array);

        let obstructions = [];
        facet.obstructions.map(obs => {
            let position = obs.height.position;
            // TODO: get updated position after drag
            let obstructionShape = obs.cords.map(vector => ({
                x: vector.x,
                y: vector.y,
                z: vector.z,
            }));
            let type = obs.type;
            let height = obs.height;
            obstructions.push({ "polygon": obstructionShape, "type": type, "height": height });
        });

        let detail = facet.details;
        let name = facet.label.element.innerHTML;
        let data2d = get2dData(polygonShape, obstructions, i);


        transformedData.push({
            "label": name, "polygon": polygonShape, "obstruction": obstructions,
            "rotated_polygon": data2d.segment2dPoints, "rotated_obstruction": data2d.Obstacle2dPoints,
            "detail": detail, "dormerData": data2d.dormerData
        });
        // console.log(JSON.stringify(transformedData))
    }
    return transformedData;
}

// Finds all dormers inside the parent segments
function findDormers(parentPoints, currentIndex) {
    DXF_VARS.dormerData.push([]); // register the parent by its index first.
    for (let i = 0; i < POLYGONS.length; i++) {

        if (currentIndex !== i) {
            let polygonVertices = POLYGONS[i].shape.geometry.attributes.position.array;
            let dormerPoints = [];
            for (let j = 0; j < polygonVertices.length; j += 3) {
                dormerPoints.push({ x: polygonVertices[j], y: polygonVertices[j + 1], z: polygonVertices[j + 2] });
            }
            let isInside = isPolygonInsidePolygon(dormerPoints, parentPoints);
            if (isInside) {
                DXF_VARS.dormerData[currentIndex].push(i);

            }
        }
    }
}

export function getVerticesData(polygonVertices) {
    let polygonShape = [];
    for (let j = 0; j < polygonVertices.length; j += 3) {
        let vertex = {
            x: polygonVertices[j],
            y: polygonVertices[j + 1],
            z: polygonVertices[j + 2]
        };
        polygonShape.push(vertex);
    }
    return polygonShape;
}

function traverseAndClean() {
    let visited = new Set();
    let elementMap = new Map();

    function traverse(index) {
        if (visited.has(index)) return;
        visited.add(index);

        let currentArray = DXF_VARS.dormerData[index];
        let newArray = [];

        for (let element of currentArray) {
            if (!elementMap.has(element)) {
                elementMap.set(element, index);
                traverse(element);
                newArray.push(element);
            }
        }

        DXF_VARS.dormerData[index] = newArray;
    }

    function removeFromOtherArrays() {
        for (let [element, index] of elementMap.entries()) {
            for (let i = 0; i < DXF_VARS.dormerData.length; i++) {
                if (i !== index) {
                    DXF_VARS.dormerData[i] = DXF_VARS.dormerData[i].filter(e => e !== element);
                }
            }
        }
    }

    for (let i = 0; i < DXF_VARS.dormerData.length; i++) {
        if (!visited.has(i)) {
            traverse(i);
        }
    }

    removeFromOtherArrays();
    return DXF_VARS.dormerData;
}




